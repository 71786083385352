<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Moso - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>
             
                <b-col md="2">
                  <b-form-group label="Tipo de Documento :">
                    <b-form-select disabled v-model="moso.document_type" :options="document_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input disabled v-model="moso.document_number" class="form-control" ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Nombres:">
                    <b-form-input type="text" disabled v-model="moso.name" placeholder="Ingrese su nombre"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Token:">
                    <b-form-input type="password" disabled v-model="moso.token"></b-form-input>
                  </b-form-group>
                </b-col>
           
                <b-col md="3">
                  <b-form-group label="Usuario :" >
                    <b-form-input type="text" disabled v-model="moso.description" ></b-form-input>
                  </b-form-group>
                </b-col>
          
                <b-col md="2">
                  <b-form-group label="Teléfono :">
                    <b-form-input type="text" disabled v-model="moso.phone"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group label="Email :">
                    <b-form-input type="email" disabled v-model="moso.email"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado :">
                    <b-form-select disabled v-model="moso.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>
         

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/moso/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'

export default {
  name: "ClienteView",
  props: ["id_moso"],
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module:'Moso',
      role:5,
      moso: {
        id_moso: "",
        document_type: "1",
        document_number: "",
        name: "",
        country: "PE",
        ubigee: "",
        address: "",
        phone: "",
        email: "",
        city: "",
        nationality: "",
        coming_from: "",
        profession: "",
        age: "",
        sex: "",
        marital_status: "",
        state: 1,
      },
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewMoso();
    
  },
  methods: {
    ViewMoso,


  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};



//ver usuario
function ViewMoso() {
  let me = this;
  let id_moso = je.decrypt(me.id_moso);
  let url = me.url_base + "moso/view/" + id_moso;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
      module: me.module,
      role: me.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.moso.id_moso = response.data.result.id_moso;
        me.moso.document_type = response.data.result.document_type;
        me.moso.document_number = response.data.result.document_number;
        me.moso.name = response.data.result.name;
        me.moso.phone = response.data.result.phone;
        me.moso.description = response.data.result.description;
        me.moso.email = response.data.result.email;
        me.moso.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

</script>
